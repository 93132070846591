export const PATHS = {
  HOME: "/",
  HOME_B: "/qr-code-generator",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  TERMS_OF_USE: "/terms-of-use",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIES_POLICY: "/cookies-policy",
  CONTACT_US: "/contact-us",
  FAQ: "/faq",
  PRICING: "/pricing",
  WHOWEARE: "/who-we-are",
  QR_EDITOR: "/qr-editor",
  NOT_FOUND: "/404",
  MY_ACCOUNT: "/my-account",
  MY_QR_CODES: "/my-qr-codes",
  MY_QR_CODES_DETAILS: "/my-qr-codes-details",
  MY_QR_ANALYTICS: "/my-qr-analytics",
  MY_BILLING: "/my-billing",
  PAYMENT: "/payment",
  THANK_YOU: "/thank-you",
  VERIFY_PAYMENT: "/verify-payment",
  QR_URL: "/url",
  INACTIVE: "/inactive",
  RESET: "/reset",
  UNSUBSCRIBE: "/unsubscribe",
  UNSUBSCRIBE_REASONS: "/unsubscribe-reasons",
  UNSUBSCRIBE_CONFIRMATION: "/unsubscribe-confirmation",
};
