"use client";

import React from "react";

import { UserProps, UserStatus } from "@/types/user";
import { QrStatus } from "@/types/qr";
import { SubscriptionStatus } from "@/types/subscription";
import { UserContextProps } from "./types";
import { PlanProps } from "@/types/plan";
import { BillingData } from "@/containers/MyBillingContainer/components/BillingInformation/types";

export const USER_INITIAL_DATA: UserProps = {
  accountId: "",
  userId: "",
  firstName: "",
  lastName: "",
  phone: "",
  userStatus: UserStatus.REGISTERED,
  qrStatus: QrStatus.DRAFT,
  selectedSubscriptionId: "",
  email: "",
  freeTrialEndsAt: null,
  freeTrialStartsAt: null,
  subscription: {
    id: "",
    status: SubscriptionStatus.REGISTERED,
    subscribed: false,
    plan: undefined,
  },
};

export const USER_BILLING_INITIAL_DATA: BillingData = {
  address: "",
  city: "",
  country: "",
  createdAt: "",
  firstName: "",
  id: "",
  lastName: "",
  phone: "",
  taxId: "",
  updatedAt: "",
  zipCode: "",
};

let UserContext: React.Context<UserContextProps>;
const { Provider } = (UserContext =
  React.createContext<UserContextProps>(undefined));

const UserProvider = ({ children }: any) => {
  const [prices, setPrices] = React.useState<PlanProps[]>([]);
  const [userData, setUserDataState] =
    React.useState<UserProps>(USER_INITIAL_DATA);

  const [userBillingData, setUserBillingDataState] =
    React.useState<BillingData>(USER_BILLING_INITIAL_DATA);

  const setUserData = (data: UserProps) => {
    setUserDataState(data);
  };

  React.useEffect(() => {
    setUserData(USER_INITIAL_DATA);
  }, []);

  return (
    <Provider
      value={{
        user: userData,
        setUser: setUserData,
        prices,
        setPrices,
        userBillingData: userBillingData,
        setUserBillingData: setUserBillingDataState,
      }}
    >
      {children}
    </Provider>
  );
};

export { UserContext, UserProvider };
